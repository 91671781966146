import $ from 'jquery';
import Waypoint from 'waypoints';
import ScrollReveal from 'scrollreveal';
import platform from 'platform';
import getLatestRelease from 'get-latest-release';
import tracking from './tracking';

const owner = 'kelyvin';
const repo = 'Google-Messages-For-Desktop';
const latestReleaseUrl = 'https://github.com/kelyvin/Google-Messages-For-Desktop/releases/latest';

const OS_CONFIG = {
  OSX: {
    title: 'mac',
    name: 'OS X',
    icon: 'apple',
    img: 'images/screenshots/fullscreen/mac-fullscreen.png',
    btnText: '<i class="fa fa-apple"></i> Download for Mac'
  },
  WINDOWS: {
    title: 'windows',
    name: 'Windows',
    icon: 'apple',
    img: 'images/screenshots/fullscreen/windows-fullscreen.png',
    btnText: '<i class="fa fa-windows"></i> Download for Windows'
  },
  LINUX: {
    title: 'linux',
    name: 'Ubuntu',
    icon: 'linux',
    btnText: '<i class="fa fa-linux"></i> Download for Linux'
  }
};

export default class MessagesForDesktop {
  constructor() {
    this.osConfig = null;
    this.scrollReveal = ScrollReveal();

    this.$document = $('html, body, .messages-landing-page');
    this.$navbar = $('#navbar');
    this.$navLinks = $('a.nav-link');
    this.$brandLink = $('#brand-link');

    // OS overrideable assets
    this.$heroFullscreenImg = $('#hero-fullscreen-image');
    this.$macBtn = $('.mac-btn');
    this.$windowsBtn = $('.windows-btn');
    this.$linuxBtn = $('.linux-btn');
    this.$downloadBtn = $('.download-btn');
    this.$primaryDownloadBtn = $('.btn-primary.download-btn');
  }

  scrollTop(top) {
    this.$document.animate({
      scrollTop: top
    });
  };

  setOSDownloadLinks(result) {
    const assets = result.assets;

    // Go through each download link
    // Check if the `name` of the file has mac or windows
    // Update the appropriate link with `download_url`
    if (assets.length > 0 && this.osConfig) {
      const { title } = this.osConfig;

      assets.forEach((result) => {
        const name = result.name || '';
        const downloadUrl = result.download_url || latestReleaseUrl;

        if (name.indexOf(title) >= 0) {
          this.$primaryDownloadBtn.attr('href', downloadUrl);
        }

        if (name.indexOf('mac') >= 0) {
          this.$macBtn.attr('href', downloadUrl);
        } else if (name.indexOf('windows') >= 0) {
          this.$windowsBtn.attr('href', downloadUrl);
        } else if (name.indexOf('linux') >= 0) {
          this.$linuxBtn.attr('href', downloadUrl);
        }
      });
    }
  };

  renderOSInfo(shouldRenderImg = true) {
    if (this.osConfig) {
      const { title, btnText, img } = this.osConfig;
      this.$primaryDownloadBtn
        .attr('data-label', title)
        .attr('data-action', 'click')
        .attr('data-category', 'MainDownload')
        .html(btnText);

      // We render the mac screenshot by default
      if (!shouldRenderImg) {
        const imgEl = $('<img>', { src: img });
        this.$heroFullscreenImg.html(imgEl);
      }
    }
  }

  initOSInfo() {
    if (platform && platform.os && platform.os.family) {
      const osType = platform.os.family;

      if (osType.indexOf(OS_CONFIG.OSX.name) >= 0) {
        this.osConfig = OS_CONFIG.OSX;
      } else if (osType.indexOf(OS_CONFIG.WINDOWS.name) >= 0) {
        this.osConfig = OS_CONFIG.WINDOWS;
      } else if (osType.indexOf(OS_CONFIG.LINUX.name) >= 0) {
        this.osConfig = OS_CONFIG.LINUX;
      }
    }

    this.renderOSInfo();
  }

  initDownloadLinks() {
    getLatestRelease({ owner, repo })
      .then((result) => {
        this.setOSDownloadLinks(result);
      }, (error) => {
        tracking.trackEvent({
          category: 'Error',
          action: 'github',
          label: error
        })
      });
  };

  initNavLinksListener() {
    this.$brandLink.click((event) => {
      this.scrollTop(0);
    });

    this.$navLinks.click((event) => {
      const $target = $(event.target);
      const href = $target.attr('href');

      this.scrollTop($(href).offset().top);
      return false;
    });
  }

  initNavbarWaypoint() {
    new Waypoint({
      element: document.getElementById('hero-call-to-action'),
      handler: (direction) => {
        if (direction === 'down') {
          this.$navbar.addClass('top');
        } else if (direction === 'up') {
          this.$navbar.removeClass('top');
        }
      }
    });
  }

  initScrollReveal()  {
    this.scrollReveal.reveal('.screenshot-image', {
      duration: 800,
      scale: 0.8,
    });

    this.scrollReveal.reveal('.notification-images img', {
      duration: 800,
      origin: 'right',
      delay: 300
    }, 300);

    this.scrollReveal.reveal('.download-section .btn', {
      duration: 800,
    });

    this.scrollReveal.reveal('.how-it-works-summary', {
      duration: 800
    });
  }

  init() {
    this.initOSInfo();
    this.initNavLinksListener();
    this.initDownloadLinks();
    this.initNavbarWaypoint();
    this.initScrollReveal();

    tracking.init()
  };
};
