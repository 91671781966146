import $ from 'jquery';

// Set up automatic event tracking with data-attributes
const init = () => {
  if (gtag) {
    const $clickTrackingEl = $('[data-action]')

    $clickTrackingEl.click(function () {
      let category = $(this).data('category') ? $(this).data('category') : ''
      let action = $(this).data('action') ? $(this).data('action') : ''
      let label = $(this).data('label') ? $(this).data('label') : ''

      trackEvent({
        category,
        action,
        label
      })
    })
  }
}

export const trackPage = (title) => {
  if (gtag) {
    gtag('config', GA_TRACKING_ID, {
      page_title: title
    })
  }
}

export const trackEvent = (options) => {
  const { category = '', action = '', label = '', value = '' } = options

  let eventOptions = {
    event_category: category,
    event_label: label,
    value: value
  }

  if (gtag) {
    gtag('event', action, eventOptions)
  }
}

export default {
  init,
  trackPage,
  trackEvent
}
